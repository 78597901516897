import React from 'react'
import { graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion'
import { RichText } from 'prismic-reactjs'
import AnleitungsList from '../components/AnleitungsList'
function Zielkatalog(props) {
  const zielNodes = props.data.allPrismicZielformulierungen.nodes
  const unterkategorieNodes = props.data.allPrismicUnterkategorien.nodes
  const maincatNodesFromZiel = {}
  const allMaincatNodes = {}
  const undefinedMaincatName = 'Nicht definierte Kategorie'
  const undefinedSubcatName = 'Nicht definierte Unterkategorie'

  // 1. Alle Hauptkategorien aus Prismic abrufen
  props.data.allPrismicKategorien.nodes.forEach((maincat) => {
    allMaincatNodes[maincat.data.maincat.text] = {
      definition: maincat.data.definition,
      maincat: maincat.data.maincat.text,
      subcat: [],
    }
  })

  // 2. Erstellen einer Liste aller Unterkategorien
  const allSubcats = unterkategorieNodes.reduce((acc, subcat) => {
    acc[subcat.data.unterkategorie.text] = {
      definition: subcat.data.definition,
      unterkategorie: subcat.data.unterkategorie.text,
      untertitel: subcat.data.subtitle,
      maincat:
        subcat.data.maincat.document?.data.maincat.text || undefinedMaincatName, // Standardmäßig "Nicht definierte Kategorie", wenn keine Hauptkategorie zugewiesen ist
    }
    return acc
  }, {})

  // 3. Zuweisungen für Hauptkategorien aus Zielformulierung erstellen
  for (let index = 0; index < zielNodes.length; index++) {
    const element = zielNodes[index]

    // Prüfen, ob eine Unterkategorie zugewiesen ist
    if (element.data.subcat.document) {
      const subcatName = element.data.subcat.document.data.unterkategorie.text

      // Sicherstellen, dass der Zugriff auf maincatName sicher ist
      const maincatName =
        element.data.subcat.document.data.maincat?.document?.data.maincat
          .text || undefinedMaincatName

      // Hauptkategorie hinzufügen, falls sie noch nicht existiert
      if (!maincatNodesFromZiel[maincatName]) {
        maincatNodesFromZiel[maincatName] = {
          definition: element.data.subcat.document.data.maincat?.document?.data
            .definition || { raw: [], text: '' }, // Standarddefinition, falls maincat nicht existiert
          maincat: maincatName,
          subcat: [],
        }
      }

      // Unterkategorie hinzufügen, falls sie noch nicht in der Hauptkategorie existiert
      if (!maincatNodesFromZiel[maincatName].subcat.includes(subcatName)) {
        maincatNodesFromZiel[maincatName].subcat.push(subcatName)
      }

      // Sicherstellen, dass die Haupt- und Unterkategorie in allMaincatNodes existieren
      if (!allMaincatNodes[maincatName]) {
        allMaincatNodes[maincatName] = {
          definition: element.data.subcat.document.data.maincat?.document?.data
            .definition || { raw: [], text: '' }, // Standarddefinition, falls maincat nicht existiert
          maincat: maincatName,
          subcat: [],
        }
      }
      if (!allMaincatNodes[maincatName].subcat.includes(subcatName)) {
        allMaincatNodes[maincatName].subcat.push(subcatName)
      }
    }
  }

  // 4. Zusammenführen der Hauptkategorien
  const mergedMaincatNodes = { ...allMaincatNodes, ...maincatNodesFromZiel }

  // 5. "Nicht definierte" Hauptkategorie hinzufügen, falls nötig
  if (!mergedMaincatNodes[undefinedMaincatName]) {
    mergedMaincatNodes[undefinedMaincatName] = {
      definition: {
        raw: [
          {
            type: 'paragraph',
            text: 'Kategorie für nicht zugeordnete Unterkategorien und Ziele.',
            spans: [],
          },
        ],
        text: 'Kategorie für nicht zugeordnete Unterkategorien und Ziele.',
      },
      maincat: undefinedMaincatName,
      subcat: [],
      subcatNodes: {},
    }
  }

  // 6. Zuweisen von Unterkategorien zu den Hauptkategorien und Hinzufügen von Zielen
  for (let subcatName in allSubcats) {
    const subcatData = allSubcats[subcatName]
    const maincatName = subcatData.maincat

    // Sicherstellen, dass die Hauptkategorie existiert
    if (!mergedMaincatNodes[maincatName]) {
      mergedMaincatNodes[maincatName] = {
        definition: { raw: [], text: '' }, // Standarddefinition
        maincat: maincatName,
        subcat: [],
        subcatNodes: {},
      }
    }

    // Unterkategorie den subcats hinzufügen, falls noch nicht vorhanden
    if (!mergedMaincatNodes[maincatName].subcat.includes(subcatName)) {
      mergedMaincatNodes[maincatName].subcat.push(subcatName)
    }
    // Initialisieren von subcatNodes, falls es noch nicht existiert
    if (!mergedMaincatNodes[maincatName].subcatNodes) {
      mergedMaincatNodes[maincatName].subcatNodes = {}
    }
    // Hinzufügen der Unterkategorie zu subcatNodes, falls noch nicht vorhanden
    if (!mergedMaincatNodes[maincatName].subcatNodes[subcatName]) {
      mergedMaincatNodes[maincatName].subcatNodes[subcatName] = {
        unterkategorie: subcatName,
        untertitel: subcatData.untertitel,
        definition: subcatData.definition,
        ziele: [],
      }
    }
  }

  // 7. Zuweisen von Zielen zu den Unterkategorien
  for (let index = 0; index < zielNodes.length; index++) {
    const element = zielNodes[index]
    const zielName = element.data.zielformulierung.text

    if (element.data.subcat.document) {
      const subcatName = element.data.subcat.document.data.unterkategorie.text

      // Sicherstellen, dass der Zugriff auf maincatName sicher ist
      const maincatName =
        element.data.subcat.document.data.maincat?.document?.data.maincat
          .text || undefinedMaincatName

      if (
        mergedMaincatNodes[maincatName] &&
        mergedMaincatNodes[maincatName].subcatNodes[subcatName]
      ) {
        mergedMaincatNodes[maincatName].subcatNodes[subcatName].ziele.push(
          zielName,
        )
      }
    } else {
      // Ziel ohne Unterkategorie der "Nicht definierten Unterkategorie" zuweisen
      if (
        !mergedMaincatNodes[undefinedMaincatName].subcatNodes[
          undefinedSubcatName
        ]
      ) {
        mergedMaincatNodes[undefinedMaincatName].subcatNodes[
          undefinedSubcatName
        ] = {
          unterkategorie: undefinedSubcatName,
          untertitel: {
            raw: [
              {
                type: 'paragraph',
                text: 'Ziele ohne Unterkategorie',
                spans: [],
              },
            ],
            text: 'Ziele ohne Unterkategorie',
          },
          definition: {
            raw: [
              {
                type: 'paragraph',
                text: 'Ziele, die keiner Unterkategorie zugeordnet sind.',
                spans: [],
              },
            ],
            text: 'Ziele, die keiner Unterkategorie zugeordnet sind.',
          },
          ziele: [],
        }
        if (
          !mergedMaincatNodes[undefinedMaincatName].subcat.includes(
            undefinedSubcatName,
          )
        ) {
          mergedMaincatNodes[undefinedMaincatName].subcat.push(
            undefinedSubcatName,
          )
        }
      }
      mergedMaincatNodes[undefinedMaincatName].subcatNodes[
        undefinedSubcatName
      ].ziele.push(zielName)
    }
  }

  // 8. Rendern des Accordions
  return (
    <>
      <h1>Zielkatalog</h1>
      <p>
        Link MARA:{' '}
        <a
          href="https://drive.google.com/file/d/1GmBVJfHkmObxSuS-hwAlj1vkxr0YBD4J/view?pli=1"
          target="_blank"
          rel="noreferrer"
        >
          Outcomes Framework (MARA)
        </a>
      </p>
      <Accordion defaultActiveKey="0">
        {Object.entries(mergedMaincatNodes).map(
          ([maincatName, maincatData], mainIndex) => (
            <Accordion.Item eventKey={mainIndex} key={`maincat-${maincatName}`}>
              <Accordion.Header>
                {maincatName}
                {/* Entfernen der Anzeige, wenn keine Unterkategorien vorhanden sind */}
              </Accordion.Header>
              <Accordion.Body>
                <RichText render={maincatData.definition.raw} />

                {maincatData.subcat && maincatData.subcat.length > 0 && (
                  <>
                    <h4>Unterkategorien</h4>
                    <Accordion defaultActiveKey="0">
                      {maincatData.subcat.map((subcatName, subIndex) => {
                        const subcatData = maincatData.subcatNodes[subcatName]
                        const isUndefinedSubcat =
                          subcatName === undefinedSubcatName

                        return (
                          <Accordion.Item
                            eventKey={subIndex}
                            key={`subcat-${maincatName}-${subcatName}`}
                          >
                            <Accordion.Header>
                              {/* Untertitel oder Unterkategoriename anzeigen */}
                              {subcatData.untertitel?.text || subcatName}
                            </Accordion.Header>
                            <Accordion.Body>
                              {/* Anzeige von Zielen, falls vorhanden */}
                              {subcatData.ziele.length > 0 && (
                                <>
                                  <h5>Zielformulierungen</h5>
                                  <Accordion defaultActiveKey="0">
                                    {subcatData.ziele.map((ziel, zielIndex) => {
                                      const zielNode = zielNodes.find(
                                        (node) =>
                                          node.data.zielformulierung.text ===
                                          ziel,
                                      )
                                      return (
                                        <Accordion.Item
                                          eventKey={zielIndex}
                                          key={`ziel-${subcatName}-${ziel}`}
                                        >
                                          <Accordion.Header>
                                            {/* Ziel-Subtitle oder Zielname anzeigen */}
                                            {zielNode.data.subtitle?.text ||
                                              ziel}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <RichText
                                              render={
                                                zielNode.data.definition.raw
                                              }
                                            />
                                            {/* Anleitungen anzeigen, falls vorhanden */}
                                            {zielNode.data.anleitungen.length >
                                              0 && (
                                              <>
                                                <h6>Anleitungen</h6>
                                                <AnleitungsList
                                                  anleitungen={
                                                    zielNode.data.anleitungen
                                                  }
                                                  showStepsList={false}
                                                />
                                              </> // Ende von Anleitungen anzeigen        
                                            )}
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      )
                                    })}
                                  </Accordion>
                                </>
                              )}
                              {/* Anzeige des Textes "Noch keine zugeordnete Ziele", wenn keine Ziele vorhanden sind */}
                              {subcatData.ziele.length === 0 && (
                                <p>Noch keine zugeordnete Ziele</p>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ),
        )}
      </Accordion>
    </>
  )
}

export default Zielkatalog

// GraphQL-Abfrage (unverändert)
export const query = graphql`
  query ZielkatalogQuery {
    allPrismicZielformulierungen {
      nodes {
        data {
          anleitungen {
            anleitung {
              document {
                ... on PrismicAnleitung {
                  data {
                    title {
                      text
                    }
                  }
                    uid
                }
              }
            }
          }
          definition {
            raw
          }
          zielformulierung {
            text
          }
          subtitle {
            text
          }
          subcat {
            document {
              ... on PrismicUnterkategorien {
                id
                data {
                  definition {
                    raw
                  }
                  unterkategorie {
                    text
                  }
                  subtitle {
                    text
                  }
                  maincat {
                    document {
                      ... on PrismicKategorien {
                        id
                        data {
                          definition {
                            raw
                            text
                          }
                          maincat {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicKategorien {
      nodes {
        data {
          definition {
            raw
            text
          }
          maincat {
            text
          }
        }
      }
    }
    allPrismicUnterkategorien {
      nodes {
        data {
          definition {
            raw
          }
          unterkategorie {
            text
          }
          subtitle {
            text
          }
          maincat {
            document {
              ... on PrismicKategorien {
                data {
                  maincat {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
